import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link,withRouter } from 'react-router-dom'
import { UploadOutlined } from '@ant-design/icons';
  import ReCAPTCHA from "react-google-recaptcha";
  import { Search, Grid, Header, Segment ,Label,List,Icon,Input} from 'semantic-ui-react'
  import { Popover,DatePicker,Popconfirm , message} from 'antd';
  import 'antd/dist/antd.css';
  import {logoutAdmin} from '../adminSignIn/store/actions/adminSignIn' 

import * as Actions from "./store/actions";
 import { compose } from "redux";
  import { connect } from "react-redux";
  import reducer from "./store/reducers";
  import withReducer from "../../store/withReducer";
  import store from "../../store"; 


  const recaptchaRef = React.createRef();
class MainMenuArea extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
    } 
 
  }
  handleLogout=()=>{
    if(this.state.email===localStorage.IPADEmail){
    this.props.dispatch(logoutAdmin())
      message.success(`You Have Successfully Logout`);
      this.props.history.push('/login')
    }else{
      message.error(`You can't logout because you are not admin`);
      /* error */
    }
    
  }
  componentWillMount(){
    if(localStorage.IPADToken){
      this.props.history.push('./menu')
    }else{
      this.props.history.push('./login')
    }
  }
  handleOpenFile=()=>{
    this.props.history.push('./createFile')
  }
  handlevisitor=()=>{
    this.props.history.push('./visitor')
  }
  /* visitor */
    render() {

     /*   console.log(this.state.exp,"this.state.exp") */
        return (
          <div>
        <div className="ipad-customer-menu-items">
        <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                    <div className="ipad-menu-services" onClick={this.handlevisitor}>
          <Icon name="file alternate" />  Legal    Visitor Form
         </div>
         {/*  */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">   
                    <div className="ipad-menu-services" >
            <a href="https://www.wowets.com/visitorpanel" style={{color:'white'}} target="_blank">
          <Icon name="newspaper outline" /> WOW Visitor Form
          </a>
         </div>
         </div>
         
           <div className="col-lg-6 col-md-6 col-12">
 
           <div className="ipad-menu-services">
           <a href="https://legalemirates.com/signin" style={{color:'white'}} target="_blank">
          <Icon name="sign-in" />  Sign In
          </a>
         </div>
             </div>
             <div className="col-lg-6 col-md-6 col-12">

<div className="ipad-menu-services" >
  <a href="https://legalemirates.com/signup" style={{color:'white'}} target="_blank">
<Icon name="user plus" />  Sign Up
</a>
</div>
 </div>
            <div className="col-lg-6 col-md-6 col-12">
                    <div className="ipad-menu-services" onClick={this.handleOpenFile}>
          <Icon name="file" /> Open a file
         </div>
            {/* <div className="ipad-menu-services" >
            <a href="https://legalemirates.com/news" style={{color:'white'}} target="_blank">
          <Icon name="newspaper outline" /> Go To News
          </a>
         </div> */}
              </div>
            <div className="col-lg-6 col-md-6 col-12">
            <Popconfirm
                 title={(<div>Only Admin logout this system. If your are admin then please enter your Email
                   <br />
                  <center> <Input onChange={(e)=>this.setState({email:e.target.value})} icon='mail outline' iconPosition='left' placeholder='Enter Email' /></center>
                 </div>)}
                 onConfirm={this.handleLogout}
                 onCancel={()=>console.log("cancel")}
                 okText="Logout"
                 cancelText="No"
                 placement="top"
               >
                <div className="ipad-menu-services">
          <Icon name="power off" />   Logout IPAD
         </div>
               </Popconfirm>
              </div>
          </div>

          </div>          
        
      
       {/*   <div className="ipad-menu-services" onClick={()=> message.success(`this feature is coming soon`)}>
          <Icon name="arrow alternate circle right outline" />    Office Boy Services
         </div> */}
      
        
        </div>
          </div>
        )
    }
}

export default compose(
  withReducer("MainMenuArea", reducer),
  connect(),withRouter
)(MainMenuArea); 