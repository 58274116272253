
import React, { Fragment } from 'react'
import IPADMenuArea from '../../../components/menu'
import './style.scss'
const IPADMenuAreaPage = () => {
    const [readMore, setReadMore] = React.useState(true);
    return (
        <Fragment>
            
           
           <IPADMenuArea
           className="AdminSignInArea"
            />
        </Fragment>
    )
}
export default IPADMenuAreaPage
/* IPADMenuArea */