


import React,{useEffect,useState} from 'react'
import { NavLink, Link } from 'react-router-dom'
import './style.scss';
import './style.css';
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import * as Actions from "../adminSignIn/store/actions";
import store from "../../store";
import { Dropdown, Input,Label,Icon,Checkbox } from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery'
/* import '../customerFileArea/paymentInvoice/style.scss' */
/* 
 {
        name:'Dashboard',
        icon:'icono izquierda fa fa-home',
        child:false,
        route:'admin',
        submenu:[]
    }, */
  
const MenuCategories=[
    {
        name:'Services',
        icon:"icono izquierda fa fa-shirtsinbulk",
        child:true,
        route:'createFile',
        id:'ServicesReceipnest',
        submenu:[
            {name:'Open a File',route:'createFile',id:"createFileReceipnest"},
            {name:'All Files',route:'allfiles',id:'allfilesReceipnest'},
            {name:'Session Roll',route:'sessions',id:'receptionistsessions'}
            /* {name:'IPAD Service',route:'ipadService',id:'ipadService'}, */
           /*  {name:'Customers',route:'quotationAll',id:"Customer"}, */
           
        ]
    },/* {
        name:'Live Chat',
        icon:'icono izquierda fa fa-commenting-o',
        child:false,
        id:'Chat',
        route:'ReceipnestChat',
        submenu:[]
    } */
    ,
   /*  {
        name:'Purchase',
        icon:"icono izquierda fa fa-cart-arrow-down",
        child:true,
        route:'quotationAll',
        id:'Purchase',
        submenu:[
            {name:'Bills',route:'AcountantChat'},
            {name:'Company/Supplier',route:'quotationAll'},
            {name:'Vendors',route:'quotationAll'},
            {name:'Product & Services',route:'quotationAll'}
           
        ]
    },{
        name:'LPO',
        icon:'icono izquierda fa fa-chrome',
        child:true,
        id:'LPO',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Voucher Receiving',
        icon:'icono izquierda fas fa fa-list-alt',
        child:true,
        id:'VoucherReceiving',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Employ Salary Slip',
        icon:'icono izquierda fa fa-drivers-license-o',
        child:true,
        id:'EmploySalarySlip',
        route:'quotationAll',
        submenu:[]
    },{
        name:'Attendance Sheet',
        icon:'icono izquierda fa fa-file-text',
        child:true,
        id:'AttendanceSheet',
        route:'quotationAll',
        submenu:[]
    },{
        name:'VAT Statement',
        icon:'icono izquierda 	fa fa-eercast',
        child:true,
        id:'VATStatement',
        route:'quotationAll',
        submenu:[]
    } */
]
function AdminSideBar ()  {
    const dispatch = useDispatch();
    $(document).ready(function(){
        $('.menu li').click(function(e){
        /*     e.preventDefault(); */
            if ($(this).hasClass('activado')){
                $(this).removeClass('activado');
                $(this).children('ul').slideUp();
            } else {
               /*  $('.menu li ul').slideUp(); */
                $('.menu li').removeClass('activado');
                $(this).addClass('activado');
                $(this).children('ul').slideDown();
            }
        });
    
        $('.btn-menu').click(function(){
            $('.contenedor-menu .menu').slideToggle();
        });
    
        $(window).resize(function(){
            if ($(document).width() > 50){
                $('.contenedor-menu .menu').css({'display' : 'block'});
            }
    
            if ($(document).width() < 50){
                $('.contenedor-menu .menu').css({'display' : 'none'});
                $('.menu li ul').slideUp();
                $('.menu li').removeClass('activado');
            }
        });
    
        $('.menu li ul li a').click(function(){
      
        });
    });
    function closeNav(){
  
        document.getElementById("mySidenav1").style.width = "0";
    }
    function openNav(){
       
        document.getElementById("mySidenav1").style.width = "260px";  
    }
    function submitHandler(e){
        e.preventDefault()
    }
    function handleBar(item){
        localStorage.setItem('receipnestBar',item)
     
    }
   
    function handleBarRemove(item){
        if(localStorage.receipnestBar===item){
            localStorage.removeItem('receipnestBar')
                 }else{
                    $(document.getElementById(localStorage.receipnestBar)).removeClass('activado');
                    $(document.getElementById(localStorage.receipnestBar)).children('ul').slideUp();
                     localStorage.setItem('receipnestBar',item)
                 }
    }
  
    function handleBarLink(item){
        if(localStorage.receipnestLink){
            if(localStorage.receipnestLink===item){

            }else{
                $(document.getElementById(localStorage.receipnestLink)).removeClass('active-Links-accountant'); 
            }

        }
        localStorage.setItem('receipnestLink',item)
        $(document.getElementById(item)).addClass('active-Links-accountant'); 
    }

   
    useEffect(() =>{
  if(localStorage.receipnestBar){
    $(document.getElementById(localStorage.receipnestBar)).addClass('activado');
    $(document.getElementById(localStorage.receipnestBar)).children('ul').slideDown();
    console.log(localStorage.receipnestBar,"localStorage.receipnestBar")
  }
  if(localStorage.receipnestLink){
    $(document.getElementById(localStorage.receipnestLink)).addClass('active-Links-accountant'); 
  }
    }
    );
    function logoutAdmin(){

   
    dispatch(Actions.logoutAdmin())
 
}
    return (
        <div style={{borderLeft:'1px solid lightgray',height:'100%'}}>
            <div className="sidenav1">
        <div classNameName="admin-side-bar-area" style={{backgroundColor:'white'}}>
            <div className="admin-profile-area-acountant">

                 <Label as='a' style={{fontSize:'20px',transform:'rotate(180deg)',marginBottom:'15px',marginLeft:'0px'}} /* color='teal' */ tag>
      <div style={{fontSize:'20px',transform:'rotate(180deg)'}}>Receptionist  Panel</div>
    </Label>
           {/*  <div className="side-bar-accountant-top-area">
          <center>
          <h2 style={{color:'white'}}>Accountant Panel</h2>   
              </center>  
       
       </div>   */}
       {/*     localStorage.setItem('adminToken1', token);
            localStorage.setItem('admin',response.data.user.firstName);
            localStorage.setItem('loginFlagAdmin','login')
            localStorage.setItem('adminName',response.data.user.firstName+" "+response.data.user.lastName);
            localStorage.setItem('adminEmail',response.data.user.email);
            localStorage.setItem('adminPhone',response.data.user.phoneNumber); */}
         {/*  <center>
          <img style={{width:'150px',borderRadius:'50%'}} src={require('../../images/profile_pic.png')} />   
          <p > <Label as='a'>
      <Icon name='user' />
       {localStorage.accountantJWT ? localStorage.AccountantName:'Muhammad Vasi'}   
    </Label> <br />
          <Label as='a'>
      <Icon name='mail' />
      {localStorage.accountantJWT ? localStorage.AccountantEmail:' vasi.hafiz@legalemirates.com'}  
     
    </Label>
         <br />
         <Label as='a'>
      <Icon name='text telephone' />
      {localStorage.accountantJWT ? localStorage.AccountantPhone:' +9710505840549'}  
     
    </Label>
    <br />
         <Label as='a' onClick={logoutAdmin}>
      <Icon name='power off' />
      Logout
     
    </Label>
         </p>
              </center>  */}
            </div>
          {/*    <div>
                 <center>
                 <form
                    className="counselling-search-bar"
                    style={{marginBottom:'20px',width:'100%'}}
                    onSubmit={submitHandler}>
                    <input
                        placeholder="search here"
                        style={{marginLeft:'-20px'}}
            
                        type="text"
                    
                    />
                    <button type="submit"><i className="fa fa-search"></i></button>
                </form>
                 </center>
             </div> */}
<div class="contenedor-menu">
		{/* <a href="#" class="btn-menu">Menu<i class="icono fa fa-bars"></i></a> */}

		<ul class="menu">
            {MenuCategories.map((item)=>{
                if(item.child){
                 return <li id={item.id} onClick={()=>handleBar(item.id)}><Link /* to={item.route} */><i style={{marginRight:'10px',marginLeft:'-5px'}} className={item.icon}></i>{item.name}<i className="icono derecha fa fa-chevron-down"></i></Link>
                 <ul>
                     {item.submenu.map(item1=>{
                         return <li ><Link id={item1.id} onClick={()=>handleBarLink(item1.id)}  to={item1.route}> {item1.name}</Link></li>
                     })}
                    
                 </ul>
             </li>
                }else{
                return 	<li id={item.id} onClick={()=>handleBarRemove(item.id)}><Link to={item.route}><i style={{marginRight:'10px',marginLeft:'-5px'}} className={item.icon}></i>{item.name}</Link></li>
                }
            })}
		
		</ul>
	</div>
        </div>
        </div>
        <div id="mySidenav1" className="sidenav" >
        <div classNameName="admin-side-bar-area sidenav1" style={{backgroundColor:'white'}}>

        <div className="admin-profile-area-acountant">

<Label as='a' style={{fontSize:'20px',transform:'rotate(180deg)',marginBottom:'15px',marginLeft:'0px'}} /* color='teal' */ tag>
<div style={{fontSize:'14px',transform:'rotate(180deg)'}}>Accountant Panel</div>
</Label>
<h3 className="sidebarTitle" >  <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a></h3>
        
{/*  <div className="side-bar-accountant-top-area">
<center>
<h2 style={{color:'white'}}>Accountant Panel</h2>   
</center>  

</div>   */}
{/*      localStorage.setItem('accountantJWT', token);
localStorage.setItem('loginFlagAccountant',response.data.user.firstName)
localStorage.setItem('AdminAccountant','login')
localStorage.setItem('AccountantName',response.data.user.firstName+" "+response.data.user.lastName);
localStorage.setItem('AccountantEmail',response.data.user.email); */}
{/* <center>
<img style={{width:'150px',borderRadius:'50%'}} src={require('../../images/profile_pic.png')} />   
<p > <Label as='a'>
<Icon name='user' />
{localStorage.accountantJWT ? localStorage.AccountantName:'Muhammad Vasi'}   
</Label> <br />
<Label as='a'>
<Icon name='mail' />
{localStorage.accountantJWT ? localStorage.AccountantEmail:' vasi.hafiz@legalemirates.com'}  

</Label>
<br />
<Label as='a'>
<Icon name='text telephone' />
{localStorage.accountantJWT ? localStorage.AccountantPhone:' +9710505840549'}  

</Label>
<br />
<Label as='a' onClick={logoutAdmin}>
<Icon name='power off' />
Logout

</Label>
</p>
</center>  */}
</div>{/* 
             <div>
              
                 <form
                    className="counselling-search-bar"
                    style={{marginBottom:'20px'}}
                    onSubmit={submitHandler}>
                    <input
                        placeholder="search here"
                    
                        type="text"
                        style={{marginLeft:'-20px'}}
                   
                    />
                    <button type="submit"><i className="fa fa-search"></i></button>
                </form>
            
             </div> */}
<div class="contenedor-menu">
		{/* <a href="#" class="btn-menu">Menu<i class="icono fa fa-bars"></i></a> */}

        <ul class="menu">
            {MenuCategories.map((item)=>{
                if(item.child){
                 return <li id={item.id} onClick={()=>handleBar(item.id)}><Link /* to={item.route} */><i style={{marginRight:'10px',marginLeft:'-5px'}} className={item.icon}></i>{item.name}<i className="icono derecha fa fa-chevron-down"></i></Link>
                 <ul>
                     {item.submenu.map(item1=>{
                         return <li ><Link id={item1.id} onClick={()=>handleBarLink(item1.id)}  to={item1.route}> {item1.name}</Link></li>
                     })}
                    
                 </ul>
             </li>
                }else{
                return 	<li id={item.id} onClick={()=>handleBarRemove(item.id)}><Link to={item.route}><i style={{marginRight:'10px',marginLeft:'-5px'}} className={item.icon}></i>{item.name}</Link></li>
                }
            })}
		
		</ul>
	</div>
        </div>
        </div>
<div style={{marginBottom:'0px',marginTop:'10px',marginLeft:'30px',backgroundColor:'white',padding:'4px'}} >
<span className="sidenav2" style={{fontSize:'25px',cursor:'pointer'}} onClick={openNav}>&#9776; Menu Area</span>
</div>
        </div>
    )
}
export default  withReducer("AdminSideBar", reducer)
  (AdminSideBar); 