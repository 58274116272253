import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'
import FooterArea from '../../../components/FooterArea'

import OpenFilesArea from '../../../components/openFile'
import './style.scss'
const openFilePage = () => {

    return (
        <Fragment>
            
           <OpenFilesArea
           className="SignInArea"
            />
         {/*    <FooterArea/> */}
        </Fragment>
    )
}
export default openFilePage
/* VisitorCustoomerArea */