import axios from "axios";
import setAuthToken from '../../setAuthToken';
import jwt_decode from 'jwt-decode';
import history from '../../../../history';
//GET ALL CARCATAGORY BY COUNTRY
export const ADMIN_SIGN_IN = "ADMIN_SIGN_IN";
export const ADMIN_SIGN_IN_LOADING =
"ADMIN_SIGN_IN_LOADING";
export const ADMIN_SIGN_IN_LOADING_FAILED =
"ADMIN_SIGN_IN_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const adminSignInLoading = ms => ({
    type: ADMIN_SIGN_IN_LOADING,
    payload: ms
    });
    
    export const adminSignInLoadingFailed = ms => ({
    type: ADMIN_SIGN_IN_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function adminSignIn(obj) {
    const request = axios.post(
    `${process.env.REACT_APP_PROFILE }/api/v1/iPadPenal/auth/signIn`,obj);
    return dispatch => {

    dispatch(adminSignInLoading());
    request
    .then(response => {    
    if (response.status == 200) {
        if(response.data.user.emailVerified === true){
            let  token  = response.data.user.token;
            localStorage.setItem('IPADToken', token);
            localStorage.setItem('IPAD','login')
            localStorage.setItem('IPADEmail',response.data.user.email);
      
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentAdmin(response.data.user.firstName));
            dispatch({
                type: ADMIN_SIGN_IN,
    payload: response.data
                });
        }
    
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        adminSignInLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(adminSignInLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(adminSignInLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(adminSignInLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


    export const setCurrentAdmin = decoded => {
        return {
            type: 'SET_CURRENT_ADMIN',
            payload: decoded
        }
      }
      export const logoutAdmin = () => dispatch => {
     
        localStorage.removeItem('IPADToken');
        localStorage.setItem('IPAD','') 
        setAuthToken(false);
        dispatch(setCurrentAdmin(undefined));
      /*  window.location.reload(history.push('/login')); */
       
       
        
      
      }