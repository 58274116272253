import React,{useEffect,useState} from 'react'
import { NavLink, Link } from 'react-router-dom'
import '../sideBar/style.scss';
import '../sideBar/style.css';
/* import './style.css' */
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import CustomerFileForm from './CustomerFileForm'
import AdminSideBar from '../sideBar/sidebar'
import withReducer from "../../store/withReducer";
import Header from '../header'
import store from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {Container} from 'react-bootstrap'

function OpenFilesArea ()  {
    
    return (
        <div>  
          {/*       <Header />   */}
        <div className="adminPanel-area">
     
            <Container fluid>
                <div className="adminPanelBackarea">
            
                <div className="row" >
              
                   {/*  <div className="col-12 admin-cols">
                <AdminSideBar />
                    </div> */}
                    <div className="col-12">
                    <CustomerFileForm />
                    </div>
                </div>
                </div>
            </Container>
        </div>
        </div>
    )
}
export default  withReducer("OpenFilesArea1", reducer)
  (OpenFilesArea); 