

import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import './style.scss';
import './style.css';
import MainMenuArea from './ipadMenu'
const IPADMenuArea = ({ className }) => {
    return (
        <div className="ForgotPasswordArea center-ALign_contianer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-12">
                    
                    </div>
                    <div className="col-12 col-md-8 col-lg-8" style={{border:'2px solid white',paddingTop:'40px',paddingBottom:'40px',borderRadius:'20px'}}>
                  {/*   <div className="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'auto',height:'500px'}}></div>
 */}               <center>  <img src={require('../../images/logo/logo2.png')} style={{width:'150px',marginBottom:'20px'}} />
                        <p style={{color:'white',paddingBottom:'15px'}}>Please select our IPAD services below according to your requirements:</p>
                        </center>
                       <MainMenuArea />
                        
                    </div>
                    <div className="col-lg-2 col-md-2 col-12">
                    
                    </div>
                </div>
               
            </div>
        </div>
    )
}
export default IPADMenuArea