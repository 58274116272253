import React, { Component } from 'react'
import Joi from 'joi-browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, Link,withRouter } from 'react-router-dom'
import { message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './formStyle.scss';
import {
    Button,
    Modal,
    FormGroup,
    FormControl,
    Accordion,
    Col,
    Row,
    Card,
    InputGroup,
    Container
  } from "react-bootstrap";
  import ReCAPTCHA from "react-google-recaptcha";
  import * as Actions from "./store/actions";
  import { compose } from "redux";
  import { connect } from "react-redux";
  import reducer from "./store/reducers";
  import withReducer from "../../store/withReducer";
  import store from "../../store";


  const recaptchaRef = React.createRef();
class FormAdminSignIn extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        email: '',
        lat:'',
        long:'',
        barCode:'',
        password:'',
        error: {},
        type:'password',
        passFlag:false,
        loaderFlag:false,
        errMessage:'',
        adminSignFlag:false,
        adminSignFlag1:false,
        signInRes:'',
        show:false,
        exp:'',
        errorMessage:true,
        suspious:false,
        suspiouMsg:'',
        codeAuth:'',
        eye:'fa-eye',
        type1111:'password'
    } 
    this.toastId=React.createRef(null);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
 
  }
  handleClose() {
    this.setState({ show: false });
  }
componentWillMount(){
  if(localStorage.IPADToken){
    this.props.history.push('./menu')
  }
}
  handleShow() {
    this.setState({ show: true });
  }
    schema = {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "string.email":
                        err.message = 'email mast be A Valid Email';
                        break;
                    default:
                        err.message = 'email can not be empity';
                        break;
                }
            });
            return errors;
        }),
     
        password:Joi.string().required().error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    default:
                        err.message = 'please enter password ';
                        break;
                }
            });
            return errors;
        }),
    }
    changeHandler = event => {
        const error = { ...this.state.error };
        const errorMassage = this.validationProperty(event);
        if (errorMassage) {
            error[event.target.name] = errorMassage;
        } else {
            delete error[event.target.name];
        }
        this.setState({
            [event.target.name]: event.target.value,
            error
        })
    };
    handleChange = (value) => {
        this.setState({
            country: value
        })
    };

    validationProperty = event => {
        const Obj = { [event.target.name]: event.target.value };
        const schema = { [event.target.name]: this.schema[event.target.name] }
        const { error } = Joi.validate(Obj, schema);
        return error ? error.details[0].message : null
    };

    validate = () => {
        const options = { abortEarly: false }
        const form = {
            email: this.state.email,
            password:this.state.password,
        }
        const { error } = Joi.validate(form, this.schema, options)
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message
        return errors;
    };
 
    submitHandler = event => {

        event.preventDefault()
        const error = this.validate();
        if (error) {
            this.setState({
                error: error || {}
            })
        } else {
 
              this.setState({loaderFlag:true,adminSignFlag:true,exp:''})
             /*  alert("no") */
            let obj={
                email:this.state.email,
                password:this.state.password
            }
       this.props.dispatch(Actions.adminSignIn(obj))
            /* adminSignIn */
         
      
        }
    }
  /*   handleEmailSent=()=>{
    
       let obj={
    email: this.state.email,
    deviceName: this.state.platform,
    barCodeDigit: this.state.,
    lat: location.coords.latitude,
    lon: location.coords.longitude
   }

    } */
    handleSent=()=>{
        this.setState({show:false})
    }
    handleHidePass=()=>{
this.setState({passFlag:true,type:'text'})
    }
    handleShowPass=()=>{
        this.setState({passFlag:false,type:'password'})
    }

  
      componentWillReceiveProps(nextProps){
        let resNumber1 =nextProps.AdminSignInReducer;
 
        
        if (!resNumber1.isLoading) {
          if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
              if(this.state.adminSignFlag){
        /*    alert("yes")  IPAD*/ 
        message.success(`You Have Successfully Login in IPAD Panel`);
                this.setState({signInRes:resNumber1.data,loaderFlag:false,adminSignFlag:false})
                window.scrollTo({top:0, behavior: 'smooth'})
                this.props.history.push('/menu')
              }
          } else {
          this.setState({loaderFlag:false,errFlag:true,errMessage:resNumber1.errMsg})
          }
        }
     
      }
      handleShowPassword=()=>{
        if(this.state.type1111==='password'){
         this.setState({type1111:'text',eye:'fa fa-eye-slash'})
        }else{
          this.setState({type1111:'password',eye:'fa fa-eye'})
        }
      }
    render() {

     /*   console.log(this.state.exp,"this.state.exp") */
        return (
            <form onSubmit={this.submitHandler} className='contactForm'>
                <div className="row">
                    
                    <div className="col-12">
                    <p style={{color:'white',marginBottom:'0px'}}>Username/Email*</p>  
                        <div className="formInput form-control field-design">
                         
                            <img src={require('../../images/signIn/03-01.png')} />
                            <input
                            autocomplete="off"
                              /*   placeholder="annaya@gmail.com" */
                                value={this.state.email}
                                name="email"
                                onChange={this.changeHandler}
                               /*  id="emailField" */
                                className=""
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type="email" />

                            {this.state.error.email && <p style={{fontWeight:'550',marginTop:'8px',color:'wheat'}}>{this.state.error.email}</p>}
                        </div>
                    </div>
                  
                    <div className="col-12">
                    <p style={{color:'white',marginBottom:'0px'}}>Password*</p> 
                    <div className="formInput form-control field-design1">
                    <img className="password-image1" src={require('../../images/signIn/04-01.png')} />
                            <input
                            /*     placeholder="********" */
                            autocomplete="off"
                                value={this.state.password}
                                name="password"
                                onChange={this.changeHandler}
                                style={{border:'none',background:'none',border:'none',height:'35px'}}
                                type={this.state.type} />
                             {this.state.passFlag ?   <img className="password-image2" onClick={this.handleShowPass} title="hide password" src={require('../../images/signIn/hide-01.png')} /> :
                                 <img className="password-image2" onClick={this.handleHidePass} title="show password" src={require('../../images/signIn/show-01.png')} />     }   
                           <div style={{marginTop:'8px'}} className={this.state.error.password ?"password-area" :"password-area1" }>
                            {this.state.error.password && <p style={{fontWeight:'550',color:'wheat'}}>{this.state.error.password}</p>}
                          {/*   <p style={{fontWeight:'550'}}> <NavLink style={{color:'wheat'}} exact to='/Adminpassreset'>  Forgot Password?</NavLink></p>
                               */}  </div>    
                      {/* forgotpassword */}
                        </div>
                     </div>   
                     <div className="col-12" style={{marginTop:'10px',marginBottom:'20px'}}>
                      <p style={{color:'white'}}>{this.state.errMessage}</p>   
                   
                     </div>
                    <div className="col-12" style={{marginTop:'0px'}}>
                        <div className="button-loaader-area-cutomer-signup" style={{marginTop:'5px'}}>
                      {/*   <p style={{color:'#666666',textDecoration:'underline'}}><NavLink style={{color:'#fff',textDecoration:'underline'}} exact to='/termsconditions'>  Terms & Conditions</NavLink></p>    
                       */}  <button  disabled={this.state.buttonFlag} style={{cursor:'pointer',marginTop:'5px',borderRadius:'12px',width:'70%'}} type="submit">Login </button>{this.state.loaderFlag?<div style={{marginLeft:'10px'}} className="loader"></div> :""}
                       </div>
                       
                    </div>
                </div>
           
            </form>
        )
    }
}
  const mapStateToProps=(state)=> {
    return {
        AdminSignInReducer: state.receptionistSignIn.AdminSignInReducer,
    };
  } 
export default compose(
  withReducer("receptionistSignIn", reducer),
  connect(mapStateToProps),withRouter
)(FormAdminSignIn); 