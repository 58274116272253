import axios from "axios";
//GET ALL CARCATAGORY BY COUNTRY
export const VISITOR_FORM_SUB = "VISITOR_FORM_SUB";
export const VISITOR_FORM_SUB_LOADING =
"VISITOR_FORM_SUB_LOADING";
export const VISITOR_FORM_SUB_LOADING_FAILED =
"VISITOR_FORM_SUB_LOADING_FAILED";

// HELPER ACTIONS CREATORS
//************************************** /

export const visitorRequestLoading = ms => ({
    type: VISITOR_FORM_SUB_LOADING,
    payload: ms
    });
    
    export const visitorRequestLoadingFailed = ms => ({
    type: VISITOR_FORM_SUB_LOADING_FAILED,
    payload: ms
    });

// RIDER PHINE NUBER
export function visitorRequest(obj) {
    const request = axios.post(
    `${process.env.REACT_APP_PROFILE }/api/v1/iPadPenal/visitor/visitorsData`,obj);
    return dispatch => {

    dispatch(visitorRequestLoading());
    request
    .then(response => {   
  
    if (response.status == 201 || response.status == 200) {
     console.log(response,"response visitorRequest")
    dispatch({
    type: VISITOR_FORM_SUB,
    payload: response.data
    });
  
    } else {
      
    throw new Error(JSON.stringify(response.data.response.msg));
    }
    })
    .catch(error => {
        
    if (error.response) {
    if (error.response.status == "404") {
        console.log(error.response)
    dispatch(
        visitorRequestLoadingFailed(error.response.data.message)
    );
    } else {
    dispatch(visitorRequestLoadingFailed(error.response.data.message));
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    } else if (error.request) {
    /*
    * The request was made but no response was received, `error.request`
    * is an instance of XMLHttpRequest in the browser and an instance
    * of http.ClientRequest in Node.js
    */
    dispatch(visitorRequestLoadingFailed(error.request));
    
    console.log(error.request);
    } else {
    // Something happened in setting up the request and triggered an Error
    console.log("Error", error.message);
    dispatch(visitorRequestLoadingFailed(error.message));
    }
    console.log(error);
    });
    };
    }


