import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { NavLink, Link,withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Popover,DatePicker,Popconfirm,message } from 'antd';
import "./switch.css";
import './style.scss'
import SignatureCanvas from "react-signature-canvas";
import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "../../store/withReducer";
import { Dropdown, Input,Label,Icon,Checkbox,TextArea,Header, Feed } from 'semantic-ui-react'

import { Switch } from 'antd';


import "./formStyle.scss";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
var moment = require('moment');
var QRCode = require('qrcode.react');
class VisitorForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fname: "",
      lname:"",
      personal: "",
      phone:"",
      mobileNo:"",
      email: "",
      ContactNumber: "",
      MobileNumber: "",
      mobileNo:"",
      description: "",
     show:false,
      error: {},
      firstTimeVisit:false,
      loaderFlag:false,
      visitorReqFlag:false,
      visitorNo:101,
      typingArabic:false,
      visitorExist:true,
      show1:false,
      loaderFlag1:false,
      checkVisitorFlag:false,
      visitorDetail:{},
      checkVisitorErrorFlag:false,
      checkVisitorError:'',
      visitorDetail1:{}
    };
   
  }
  handleClose=()=> {
    this.setState({ show: false });
    this.props.history.push('/menu')
    
  }

  handleShow=()=> {
    this.setState({ show: true });
  }
  handleClose1=()=> {
    this.setState({ show1: false,checkVisitorError:'',checkVisitorFlag:false,checkVisitorErrorFlag:false });
    this.props.history.push('/menu') 
  }

  handleShow1=()=> {
    this.setState({ show1: true });
  }
  schema = {
    fname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "First Name can not be Empty";
              break;
          }
        });
        return errors;
      }),
      lname: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Last Name can not be Empty";
              break;
          }
        });
        return errors;
      }),


    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "email mast be A Valid Email";
              break;
            default:
              err.message = "email can not be Empty";
              break;
          }
        });
        return errors;
      }),
  

   
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };
  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      lname: this.state.lname,
      email: this.state.email,
  
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      this.setState({loaderFlag:true,visitorReqFlag:true})
             let obj = {
               mobileNo: "+" + this.state.mobileNo,
               firstName: this.state.fname,
               lastName: this.state.lname,
               email: this.state.email,
               contact: "+" + this.state.mobileNo,
             };
             /* this.state.cardType */
             this.props.dispatch(Actions.visitorRequest(obj))
         
           
                
          

        
    }
  };
  
  
  componentWillReceiveProps(nextProps){
    let resNumber1 =nextProps.visitorFormRed;    
    if (!resNumber1.isLoading) {
      if (resNumber1.errMsg == null && !(resNumber1.data===undefined)) {
          if(this.state.visitorReqFlag){
       console.log(resNumber1.data,"file submission area")
            this.setState({loaderFlag:false,show:true, fname: "",visitorDetail1:resNumber1.data.user,
            lname: "",
            MobileNumber: "",
            ContactNumber:"",
           personal:"",
            description: "",
            email: "",visitorReqFlag:false
           })
           
          }
      } else {
      this.setState({loaderFlag:false,errMessage:resNumber1.errMsg,visitorReqFlag:false})
      }
    }
    let resNumber2 =nextProps.checkVisitorRed;    
    if (!resNumber2.isLoading) {
      if (resNumber2.errMsg == null && !(resNumber2.data===undefined)) {
        if(this.state.checkVisitorFlag){
          if(resNumber2.data.alreadyVisited){
    this.setState({show1:true,visitorDetail:resNumber2.data.visitor})
          }else{
            this.setState({checkVisitorFlag:false,loaderFlag1:false,visitorExist:false})
          }
          this.setState({loaderFlag1:false})
        }
    /* console.log(resNumber2.data,"gggggggggggggg") */
      } else {
        if(this.state.checkVisitorErrorFlag){
          this.setState({checkVisitorFlag:false,checkVisitorErrorFlag:false,loaderFlag1:false,checkVisitorError:resNumber2.errMsg})
        }
      }
    }
  }  
  handleBack=()=>{
    this.props.history.push(`/${localStorage.fileLink}`)
    window.scrollTo({top:60, behavior: 'smooth'})
  }    
  handleSearch=(e)=>{
    let search=e.target.value
    this.setState({searchFlag:true,key:search})
 
     
   }  
   handleCustomer=(item)=>{
    this.setState({addedCustomer:item,customerAdded:true,email:item.email,fname:item.firstName+" "+item.lastName,UAEAddress:item.address,MobileNumber:item.mobileNo,userId:item._id})

   /*  console.log(item) "5ef1e66c55faca3525f432d7" */
  }
  handleArabic=(e)=>{
    if(e.target.checked){
this.setState({typingArabic:true})
    }else{
      this.setState({typingArabic:false})
    }
  }  
  handleFrestTime=(e)=>{
    if(e.target.checked){
      this.setState({firstTimeVisit:true})
          }else{
            this.setState({firstTimeVisit:false})
          }  
  }  
  handleVisitorCheck=(e)=>{
    e.preventDefault()
    this.setState({
      loaderFlag1: true,
      checkVisitorFlag: true,
      checkVisitorErrorFlag: true,
      mobileNo: this.state.mobileNo,
    });
    this.props.dispatch(Actions.checkVisitor(this.state.mobileNo));
      }                        
  render() {
    const options1=[
      { level: 'Select Type', value: '' },
    /*   { level: 'CARD', value: 'CARD' }, */
      { level: 'CASH', value: 'CASH' }
  ]
  const options1122=[
    { level: 'Select Type', value: '' },
  /*   { level: 'CARD', value: 'CARD' }, */
    { level: 'Personal', value: 'personal' },
    { level: 'Company', value: 'company' }
]
const options1111=[
  { level: 'Select Gender', value: '' },
/*   { level: 'CARD', value: 'CARD' }, */
  { level: 'Male', value: 'male' },
  { level: 'Female', value: 'female' }
]


    return (
      <div>
        {this.state.visitorExist ? (
          <div
            className="VisitorArea center-ALign_contianer"
            style={{ color: "white" }}
          >
            <div className="check-visitor-area-invisitor-block">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2 col-md-2 col-12"></div>
                  <div
                    className="col-12 col-md-8 col-lg-8"
                    style={{
                      border: "2px solid white",
                      paddingTop: "40px",
                      paddingBottom: "40px",
                      borderRadius: "20px",
                    }}
                  >
                    {/*   <div className="calendly-inline-widget" data-url="https://calendly.com/legalemirates/legal-advocate?background_color=515151&text_color=ffffff&primary_color=a3a3a3" style={{miWidth:'auto',height:'500px'}}></div>
                     */}{" "}
                    <center>
                      {" "}
                      <img
                        src={require("../../images/logo/logo2.png")}
                        style={{ width: "150px", marginBottom: "20px" }}
                      />
                      <p style={{ color: "white", paddingBottom: "15px" }}>
                        To access the visitor form please enter your Phone
                        number
                      </p>
                    </center>
                    <form className="contactForm">
                      <div className="row">
                        <div className="col-12">
                          <div className="formInput15">
                            <PhoneInput
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                              onlyCountries={[
                                "ae",
                                "pk",
                                "us",
                                "gb",
                                "bd",
                                "in",
                                "eg",
                                "kz",
                                "ir",
                                "sa",
                                "ph",
                                "om",
                                "kw",
                              ]}
                              masks={{
                                ae: ".. ... ....",
                                at: "... .......",
                                us: "... ... ...",
                                gb: ".... ... ..",
                                bd: "... .... ...",
                                in: "... .... ...",
                                eg: "... .... ...",
                                kz: "... .... ...",
                                ir: "... .... ...",
                                sa: "... .... ..",
                                ph: "... .... ...",
                                om: "... .... .",
                                kw: "... .... ...",
                                pk: "... .......",
                              }}
                              country={"ae"}
                              value={this.state.mobileNo}
                              onChange={(value, country, e, formattedValue) => {
                                this.setState({ mobileNo: value });
                              }}
                            />
                          </div>

                          <div
                            hidden
                            className="formInput form-control field-design"
                          >
                            <img
                              src={require("../../images/signIn/06-01.png")}
                            />
                            <input
                              autoComplete="off"
                              placeholder="Enter  phone  like +971501207885"
                              required
                              ref={(input) => (this.mobileNo = input)}
                              className=""
                              style={{
                                border: "none",
                                background: "none",
                                border: "none",
                                height: "35px",
                                color: "white",
                                paddingLeft: "5px",
                              }}
                              type="text"
                            />
                            <p
                              style={{
                                fontWeight: "550",
                                marginTop: "8px",
                                color: "red",
                                fontSize: "13px",
                              }}
                            >
                              {this.state.checkVisitorError}
                            </p>
                            {/*       {this.state.error.email && <p style={{fontWeight:'550',marginTop:'8px',color:'red',fontSize:'13px'}}>{this.state.error.email}</p>}
                             */}{" "}
                          </div>
                        </div>

                        <div className="col-12" style={{ marginTop: "30px" }}>
                          <div className="signon-bottom-area">
                            <p
                              style={{
                                color: "#666666",
                                textDecoration: "underline",
                              }}
                            ></p>
                            <div className="customer-file-loader-with-button">
                              {" "}
                              <span style={{ width: "50px", height: "50px" }}>
                                {this.state.loaderFlag1 ? (
                                  <div
                                    style={{ marginRight: "10px" }}
                                    className="loader"
                                  ></div>
                                ) : (
                                  ""
                                )}
                              </span>{" "}
                              <button
                                onClick={this.handleVisitorCheck}
                                className="vistoer-button"
                                disabled={this.state.mobileNo ? false : true}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Modal
                        show={this.state.show1}
                        onHide={this.handleClose1}
                        size="md"
                        centered
                      >
                        <Modal.Body
                          style={{
                            padding: "10px",
                            paddingTop: "30px",
                            paddingBottom: "30px",
                          }}
                        >
                          <div className="signup-verify-email-popup">
                            <div className="signup-verify-email-popup-top-area">
                              <center>
                                <img
                                  style={{
                                    width: "150px",
                                    marginBottom: "10px",
                                  }}
                                  src={require("../../images/signIn/sdasd.png")}
                                />
                              </center>
                            </div>
                            <div className="signup-verify-email-popup-bottom-area">
                              <center>
                                <p
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "30px",
                                  }}
                                >
                                  Already Registered!
                                </p>
                                <p>
                                  Dear{" "}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: "550",
                                    }}
                                  >
                                    {this.state.visitorDetail.firstName +
                                      " " +
                                      this.state.visitorDetail.lastName}
                                  </span>{" "}
                                  you have already registered our visitor form
                                  from your email address{" "}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: "550",
                                    }}
                                  >
                                    {this.state.visitorDetail.email}
                                  </span>{" "}
                                  and phone number{" "}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: "550",
                                    }}
                                  >
                                    {this.state.visitorDetail.mobileNo}
                                  </span>
                                  . Please wait our lawyer will contact you in a
                                  while. You last time visited on{" "}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: "550",
                                    }}
                                  >
                                    {this.state.visitorDetail.visitingDate &&
                                      moment(
                                        this.state.visitorDetail.visitingDate[
                                          this.state.visitorDetail.visitingDate
                                            .length - 1
                                        ]
                                      ).format("MMMM Do YYYY, h:mm:ss a")}
                                  </span>{" "}
                                  You current visited on{" "}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: "550",
                                    }}
                                  >
                                    {this.state.visitorDetail.updatedAt &&
                                      moment(
                                        this.state.visitorDetail.updatedAt
                                      ).format("MMMM Do YYYY, h:mm:ss a")}
                                  </span>{" "}
                                  . Your visitor number is{" "}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      fontWeight: "550",
                                    }}
                                  >
                                    BZ/{this.state.visitorDetail.visitorNo}/2020
                                  </span>
                                  .
                                </p>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "170px",
                                    height: "170px",
                                  }}
                                >
                                  <QRCode
                                    size="170"
                                    style={{ width: "170px" }}
                                    value={` Dear:${
                                      this.state.visitorDetail.firstName +
                                      " " +
                                      this.state.visitorDetail.lastName
                                    } \n Your email:${
                                      this.state.visitorDetail.email
                                    }  \n  Your visitor No: BZ/${
                                      this.state.visitorDetail.visitorNo
                                    }/${new Date().getFullYear()} \n You visited on:${
                                      this.state.visitorDetail.updatedAt &&
                                      moment(
                                        this.state.visitorDetail.updatedAt
                                      ).format("MMMM Do YYYY, h:mm:ss a")
                                    }  \n \n Bader Al Zaabi Advocates Office \n Contact:+971 4 2555111 \n Email info@legalemirates.com \n Website: https://legalemirates.com/`}
                                  />
                                  <img
                                    src={require("../../images/favicon.png")}
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      left: "0",
                                      bottom: "0",
                                      right: "0",
                                      margin: "auto",
                                    }}
                                  />
                                </div>
                              </center>
                              <hr />
                              <center>
                                {" "}
                                <button
                                  className="sent-button-des"
                                  style={{ color: "white" }}
                                  onClick={this.handleClose1}
                                >
                                  OK
                                </button>
                              </center>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </form>
                  </div>
                  <div className="col-lg-2 col-md-2 col-12"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="receipnest-file-customer-files-area65"
            style={{ paddingBottom: "100px" }}
          >
            <div
              style={{
                padding: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <a class="floatffff">
                <Popconfirm
                  title="You want to go back to the main menu! Are you sure?"
                  onConfirm={() => this.props.history.push("/menu")}
                  onCancel={() => console.log("cancel")}
                  okText="Yes"
                  cancelText="No"
                  placement="topLeft"
                >
                  <div className="my-float" style={{ color: "white" }}>
                    {" "}
                    <Icon name="share" />
                    Back to Menu
                  </div>
                </Popconfirm>
              </a>
              <div className="visitor-form-border-box1">
                <div className="visitor-form-border-box-left1">m</div>
                <div className="visitor-form-border-box-right1">j</div>
              </div>
              <form onSubmit={this.submitHandler} className="counsellingForm">
                <div className="caseFiletopAreaHeaderREceipnest">
                  <h1 style={{ fontWeight: "550" }}>
                    Visitor Form{" "}
                    <span
                      className="arabic-style-font-visitor"
                      style={{ marginLeft: "20px" }}
                    >
                      {" "}
                      نموذج زائر
                    </span>
                  </h1>
                  <img src={require("../../images/logo/logo55.png")} />
                </div>
                <div className="caseFiletopAreaHeaderb">
                  <p>
                    {" "}
                    <p>
                      Date{" "}
                      <span className="date-label-deisgin-box">
                        {new Date().getDate() + 1 > 9 ? "" : 0}
                        {new Date().getDate()}
                      </span>{" "}
                      <span className="date-label-deisgin-box">
                        {new Date().getMonth() + 1 > 9 ? "" : 0}
                        {new Date().getMonth() + 1}
                      </span>{" "}
                      <span className="date-label-deisgin-box">
                        {new Date().getFullYear()}
                      </span>{" "}
                    </p>
                  </p>
                  <div className="file-no-area"></div>
                </div>
                <div className="visitor-form-border-box">
                  <div className="visitor-form-border-box-left">m</div>
                  <div className="visitor-form-border-box-right">j</div>
                </div>

                <div className="row">
                  <div className="col-12" style={{ marginTop: "20px" }}>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="row">
                          {/*    <div className="col-12 col-md-6">
                     <div className="client-file-area-left-side">
                      <p>تسجيل الملف</p>   
                   <div>

<span className="checkbo-label-A" >A</span>
                   <input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2"/>
    <label for="styled-checkbox-2"></label>
                   </div>

 
                    </div>  
                     </div> */}
                        </div>
                        <div className="row">
                          {/*  <div className="col-12 col-md-4">
                     <div className="client-file-area-left-side1">
                      <p>فرد 
</p>   
                   

 
                    </div>  
                     </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="visitor-type-direction">
                      <p> Typing In Arabic </p>{" "}
                      <div>
                        {" "}
                        <label className="switch99">
                          <input
                            onChange={this.handleArabic}
                            type="checkbox"
                            id="togBtn"
                          />
                          <div className="slider99 round99">
                            <span className="on99">Yes</span>
                            <span className="off99">No</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-6">
              <div className="formInput">
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  File No<div style={{fontSize:'12px',display:'inline'}}>(For Existing Clients)</div>
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
رقم الملف  
                </span>
                </div>
                <input
                  placeholder=""
                  className="form-control"
                  type="text"
                  dir={this.state.typingArabic?"rtl":"ltr"}
                  ref={(input)=>this.fileNo=input}
                />

              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formInput">
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
                  Case No<div style={{fontSize:'12px',display:'inline'}}>(For Existing Clients)</div>
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
القضية رقم  
                </span>
                </div>
                <input
                dir={this.state.typingArabic?"rtl":"ltr"}
                  placeholder=""
                  className="form-control"
                  type="text"
                  ref={(input)=>this.caseNo=input}
                />

              </div>
            </div> */}

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          First Name*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          الاسم الاول
                        </span>
                      </div>
                      <input
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        placeholder=""
                        className="form-control"
                        type="text"
                        value={this.state.fname}
                        onChange={this.changeHandler}
                        name="fname"
                      />

                      {this.state.error.fname && (
                        <p style={{ fontWeight: "550", marginTop: "8px" }}>
                          {this.state.error.fname}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Last Name*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          الكنية
                        </span>
                      </div>
                      <input
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        placeholder=""
                        className="form-control"
                        type="text"
                        onChange={this.changeHandler}
                        value={this.state.lname}
                        name="lname"
                      />

                      {this.state.error.lname && (
                        <p style={{ fontWeight: "550", marginTop: "8px" }}>
                          {this.state.error.lname}
                        </p>
                      )}
                    </div>
                  </div>

                  <div hidden className="col-12 col-lg-6 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Personal*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          شخصي
                        </span>
                      </div>
                      <select
                        value={this.state.personal}
                        className="form-control"
                        onChange={this.changeHandler}
                        name="personal"
                      >
                        {options1122.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.level}
                          </option>
                        ))}
                      </select>
                      {this.state.error.personal && (
                        <p>{this.state.error.personal}</p>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-6">
              <div className="formInput">
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
              Job Title
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
عنوان وظيفي 
                </span>
                </div>
                <input
                dir={this.state.typingArabic?"rtl":"ltr"}
                  placeholder=""
                  className="form-control"
                  type="text"
                  ref={(input)=>this.jobTitle=input}
                />

              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="formInput">
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
              Date of Birth
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
تاريخ الولادة
                </span>
                </div>
                <input
                dir={this.state.typingArabic?"rtl":"ltr"}
                  placeholder=""
                  className="form-control"
                  type="date"
                  ref={(input)=>this.dob=input}
                />

              </div>
            </div>


            <div className="col-12 col-lg-6 col-md-6">
                        <div className="formInput">
                        <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
              Gender
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
جنس
                </span>
                </div>
                                    <select
                                        ref={select=>this.gender=select}
                                        className="form-control"
                                        >
                                        {options1111.map(option => (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.level}
                                            </option>
                                        ))}
                                    </select>
                       </div>
                    </div>
                    <div className="col-12 col-md-6">
              <div className="formInput">
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
             Company Name
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>

اسم الشركة
                </span>
                </div>
                <input
                dir={this.state.typingArabic?"rtl":"ltr"}
                  placeholder=""
                  className="form-control"
                  type="text"
                  ref={(input)=>this.compnayName=input}
                />

              </div>
            </div> */}
                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Email Address*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          عنوان البريد الالكترونى
                        </span>
                      </div>
                      <input
                        placeholder=""
                        value={this.state.email}
                        name="email"
                        onChange={this.changeHandler}
                        className="form-control"
                        type="text"
                      />

                      {this.state.error.email && (
                        <p style={{ fontWeight: "550", marginTop: "8px" }}>
                          {this.state.error.email}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="formInput">
                      <p
                        style={{
                          marginBottom: "0px",
                          color: "#282e3f",
                          fontWeight: "550",
                          textTransform: "capitalize",
                        }}
                      ></p>
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Contact No*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          رقم الاتصال
                        </span>
                      </div>
                      <input
                        placeholder=""
                        value={"+"+this.state.mobileNo}
                        name="ContactNumber"
                        // onChange={this.changeHandler}
                        className="form-control"
                        type="text"
                      />

                      {this.state.error.ContactNumber && (
                        <p style={{ fontWeight: "550", marginTop: "8px" }}>
                          {this.state.error.ContactNumber}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-4">
              <div className="formInput">
             
                <div className="visitor-form-top-field-text">
                <p
                 style={{
                  marginBottom: "0px",
                  color: "#282e3f",
                  fontWeight: "600",
                  fontSize:'15px'
                }}
                >
          Mobile No*
                </p>
                <span  style={{
                    marginBottom: "0px",
                    color: "#282e3f",
                  }}>
رقم المحمول
                </span>
                </div>
                <input
                  placeholder=""
                  value={this.state.MobileNumber}
                  name="MobileNumber"
                  onChange={this.changeHandler}
                  className="form-control"
                  type="text"
                />

                {this.state.error.MobileNumber && (
                  <p style={{ fontWeight: "550", marginTop: "8px" }}>
                    {this.state.error.MobileNumber}
                  </p>
                )}
              </div>
            </div>
        

            <div className="col-12 col-lg-4 col-md-4">
              <div className="formInput">
             
             <div className="visitor-form-top-field-text">
             <p
              style={{
               marginBottom: "0px",
               color: "#282e3f",
               fontWeight: "600",
               fontSize:'15px'
             }}
             >
      Nationality
             </p>
             <span  style={{
                 marginBottom: "0px",
                 color: "#282e3f",
               }}>
الجنسية
             </span>
             </div>

                <input
                  className="Nationality1-list"
                  ref={(select=>this.nationality=select)}
                  name="Nationality"
                  placeholder="search..."
                  autoComplete="on"
                  style={{ width: "100%" }}
                  list="Nationality1"
                  name="Nationality"
                />
                <datalist id="Nationality1">
                  {/*      <option selected disabled>Nationality</option> 
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Åland Islands">Åland Islands</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">
                    Antigua and Barbuda
                  </option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">
                    Bosnia and Herzegovina
                  </option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">
                    British Indian Ocean Territory
                  </option>
                  <option value="Brunei Darussalam">Brunei Darussalam</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">
                    Central African Republic
                  </option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">
                    Cocos (Keeling) Islands
                  </option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo">Congo</option>
                  <option value="Congo, The Democratic Republic of The">
                    Congo, The Democratic Republic of The
                  </option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote D'ivoire">Cote D'ivoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Malvinas)">
                    Falkland Islands (Malvinas)
                  </option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern Territories">
                    French Southern Territories
                  </option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia">Gambia</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guernsey">Guernsey</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-bissau">Guinea-bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and Mcdonald Islands">
                    Heard Island and Mcdonald Islands
                  </option>
                  <option value="Holy See (Vatican City State)">
                    Holy See (Vatican City State)
                  </option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran, Islamic Republic of">
                    Iran, Islamic Republic of
                  </option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Japan">Japan</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, Democratic People's Republic of">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="Korea, Republic of">Korea, Republic of</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Lao People's Democratic Republic">
                    Lao People's Democratic Republic
                  </option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab Jamahiriya">
                    Libyan Arab Jamahiriya
                  </option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macao">Macao</option>
                  <option value="Macedonia, The Former Yugoslav Republic of">
                    Macedonia, The Former Yugoslav Republic of
                  </option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">
                    Micronesia, Federated States of
                  </option>
                  <option value="Moldova, Republic of">
                    Moldova, Republic of
                  </option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">
                    Netherlands Antilles
                  </option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">
                    Northern Mariana Islands
                  </option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palestinian Territory, Occupied">
                    Palestinian Territory, Occupied
                  </option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn">Pitcairn</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russian Federation">Russian Federation</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">
                    Saint Kitts and Nevis
                  </option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Pierre and Miquelon">
                    Saint Pierre and Miquelon
                  </option>
                  <option value="Saint Vincent and The Grenadines">
                    Saint Vincent and The Grenadines
                  </option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">
                    Sao Tome and Principe
                  </option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and The South Sandwich Islands">
                    South Georgia and The South Sandwich Islands
                  </option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard and Jan Mayen">
                    Svalbard and Jan Mayen
                  </option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">
                    Syrian Arab Republic
                  </option>
                  <option value="Taiwan, Province of China">
                    Taiwan, Province of China
                  </option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">
                    Tanzania, United Republic of
                  </option>
                  <option value="Thailand">Thailand</option>
                  <option value="Timor-leste">Timor-leste</option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">
                    Trinidad and Tobago
                  </option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">
                    Turks and Caicos Islands
                  </option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">
                    United Arab Emirates
                  </option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="United States Minor Outlying Islands">
                    United States Minor Outlying Islands
                  </option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Viet Nam">Viet Nam</option>
                  <option value="Virgin Islands, British">
                    Virgin Islands, British
                  </option>
                  <option value="Virgin Islands, U.S.">
                    Virgin Islands, U.S.
                  </option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                </datalist>
                {this.state.error.Nationality && (
                  <p>{this.state.error.Nationality}</p>
                )}
              </div>
            </div> */}

                  <div hidden className="col-12 col-md-4">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Purpose of Visit
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          غرض الزيارة
                        </span>
                      </div>
                      <input
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        placeholder=""
                        className="form-control"
                        type="text"
                        ref={(input) => (this.purpose = input)}
                      />
                    </div>
                  </div>
                  <div hidden className="col-12 col-md-4">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Reference by
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          مرجع بواسطة
                        </span>
                      </div>
                      <input
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        placeholder=""
                        className="form-control"
                        type="text"
                        ref={(input) => (this.refrence = input)}
                      />
                    </div>
                  </div>

                  <div hidden className="col-12">
                    <div className="formInput">
                      <div className="visitor-form-top-field-text">
                        <p
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                            fontWeight: "600",
                            fontSize: "15px",
                          }}
                        >
                          Description of Visit*
                        </p>
                        <span
                          style={{
                            marginBottom: "0px",
                            color: "#282e3f",
                          }}
                        >
                          وصف الزيارة
                        </span>
                      </div>

                      <textarea
                        dir={this.state.typingArabic ? "rtl" : "ltr"}
                        className="form-control"
                        value={this.state.description}
                        onChange={this.changeHandler}
                        placeholder="Type your case detail*"
                        name="description"
                      />
                      {this.state.error.description && (
                        <p>{this.state.error.description}</p>
                      )}
                    </div>
                  </div>
                  <div hidden className="col-12" style={{ marginBottom: "30px" }}>
                    <div className="office-visit-first-time">
                      <div style={{ fontWeight: "550" }}>
                        if you are visiting our Law firm for the first time must
                        Click here
                      </div>
                      <div>
                        <label className="switch99">
                          <input
                            onChange={this.handleFrestTime}
                            type="checkbox"
                            id="togBtn"
                          />
                          <div className="slider99 round99">
                            <span className="on99">Yes</span>
                            <span className="off99">No</span>
                          </div>
                        </label>
                      </div>
                      <p className="office-visit-first-time-arabic">
                        إذا كنت زائر مكتب المحاماة الخاص بنا لأول مرة الرجاء
                        النقر هنا
                      </p>
                    </div>
                  </div>
                  <div className="col-12" style={{ marginBottom: "30px" }}>
                    <p
                      style={{
                        marginBottom: "0px",
                        color: "#282e3f",
                        fontWeight: "550",
                        textTransform: "capitalize",
                      }}
                    >
                      Our professional fees of 1000 + VAT for open a file or
                      verbal legal advise will be deducted from our fees for
                      matter of further work is required.
                    </p>
                    <p
                      className="office-visit-first-time-arabic"
                      style={{
                        marginBottom: "0px",
                        color: "#282e3f",
                        fontWeight: "550",
                        textTransform: "capitalize",
                        textAlign: "right",
                      }}
                    >
                      يتقاضى المكتب 1000 درهم + ضريبة القيمة المضافة عن فتح ملف
                      او الاستشارة الشفوية و سيتم خصم المبلغ من أتعاب القضية اذا
                      ما تم قبولها او تسجيلها لدى المكتب
                    </p>{" "}
                  </div>

                  <div className="col-12" style={{ marginTop: "0px" }}>
                    <p
                      style={{
                        fontWeight: "550",
                        marginTop: "8px",
                        color: "red",
                      }}
                    >
                      {this.state.errMessage}
                    </p>

                    {/* 
<p style={{textAlign:'right',fontWeight:'550',color:'#282e3f'}}>في حال عدم دفع الأقساط المتفق عليها و الرسوم بالعقد أو عدم تزويد المكتب بكافة المستندات المطلوبة و رسوم الخبرة وكافة الرسوم الإدارية يحق للمكتب عدم الحضور بالدعوى دون أد¦ مسؤولية مع حفظ
حق المكتب في المطالبة بباقي الأتعاب المتفق عليها</p>        
              
                      
            */}
                  </div>

                  <div className="col-12" style={{ marginTop: "0px" }}>
                    <div className="signon-bottom-area1">
                      {/*  <ReCAPTCHA
                       ref={recaptchaRef}
                       sitekey="6LenmOwUAAAAAJV_zauHgqJ7FOe_bQZfztCRVGaN"
                       onChange={this.onChangeCaptha}
                       /> */}
                      <div></div>
                      <div className="customer-file-loader-with-button">
                        {" "}
                        <span style={{ width: "50px", height: "50px" }}>
                          {this.state.loaderFlag ? (
                            <div
                              style={{ marginRight: "10px" }}
                              className="loader"
                            ></div>
                          ) : (
                            ""
                          )}
                        </span>{" "}
                        <button
                          style={{ display: "inline" }}
                          disabled={this.state.buttonFlag}
                          type="submit"
                        >
                          submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  show={this.state.show}
                  onHide={this.handleClose}
                  size="md"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="heading-modal">
                      <img
                        style={{ width: "30px" }}
                        src={require("../../images/about/man-01.png")}
                      />{" "}
                      Congratulations
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      className="sent-message-area"
                      style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    >
                      <center>
                        <p>
                          <i
                            style={{ fontSize: "120px" }}
                            class="fa fa fa-check-circle-o"
                          ></i>
                        </p>
                        <h2>thank you!</h2>
                        <p style={{ fontWeight: "550" }}>
                          Dear{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: "550",
                            }}
                          >
                            {this.state.visitorDetail1.firstName +
                              " " +
                              this.state.visitorDetail1.lastName}
                          </span>
                          , your request has been successfully submitted through
                          your email{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: "550",
                            }}
                          >
                            {this.state.visitorDetail1.email}
                          </span>{" "}
                          and the contact number is{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: "550",
                            }}
                          >
                            {this.state.visitorDetail1.mobileNo}
                          </span>
                          . Please wait till further response as it has been
                          sent to the lawyer. Your visitor number is{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: "550",
                            }}
                          >
                            BZ/{this.state.visitorDetail1.visitorNo}/2020
                          </span>
                          .
                        </p>
                        <div
                          style={{
                            position: "relative",
                            width: "170px",
                            height: "170px",
                          }}
                        >
                          <QRCode
                            size="170"
                            style={{ width: "170px" }}
                            value={` Dear:${
                              this.state.visitorDetail1.firstName +
                              " " +
                              this.state.visitorDetail1.lastName
                            } \n Your email:${
                              this.state.visitorDetail1.email
                            }  \n  Your visitor No: BZ/${
                              this.state.visitorDetail1.visitorNo
                            }/${new Date().getFullYear()} \n You visited on:${
                              this.state.visitorDetail1.updatedAt &&
                              moment(
                                this.state.visitorDetail1.updatedAt
                              ).format("MMMM Do YYYY, h:mm:ss a")
                            }  \n \n Bader Al Zaabi Advocates Office \n Contact:+971 4 2555111 \n Email info@legalemirates.com \n Website: https://legalemirates.com/`}
                          />
                          <img
                            src={require("../../images/favicon.png")}
                            style={{
                              position: "absolute",
                              top: "0",
                              left: "0",
                              bottom: "0",
                              right: "0",
                              margin: "auto",
                            }}
                          />
                        </div>
                        {/*      <button className="sent-button-des" ><Link to="/menu"  style={{color:'white'}} >OK</Link></button>
                         */}{" "}
                      </center>
                    </div>
                  </Modal.Body>
                </Modal>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps=(state)=> {
  return {
    visitorFormRed: state.VisitorForm1.visitorFormRed,
    checkVisitorRed:state.VisitorForm1.checkVisitorRed


  };
}
export default compose(
  withReducer("VisitorForm1", reducer),
  connect(mapStateToProps),withRouter
)(VisitorForm); 